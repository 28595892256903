body{margin:0px!important; padding:0px; font-family: 'Roboto', sans-serif!important;
 font-size:16px!important;background-color:#f3f4f6 !important;font-weight:normal!important;}
a{text-decoration:none;color: #000;}
p{padding:0px 0px 10px 0px; margin:0px; text-align:justify;}
h1,h2,h3,h4,h5,h6{font-weight: 500;padding:0px; margin:0px 0px 10px 0px; font-family: 'Roboto', sans-serif!important;}
h1{font-size:28px}
h2{font-size:24px}
h3{font-size:22px}
h4{font-size:20px}
h5{font-size:16px}
.clear{clear:both;}
input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
	  .MuiTableRow-root .MuiTableCell-root{word-break: break-all;}
.aligncenter{text-align:center}
.alignright{float:right;margin-top:10px;clear:both}
.persummary .MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader,
.testsummary .MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader{
    min-width:80px!important;
}.MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
    width: 100%;
    display: inline-block!important;
}.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle{overflow: unset!important;}
.footerfix{background-color:#e5eff1;padding:10px 0;position:fixed;bottom:0px;width:100%}
.MuiMenuItem-gutters{display:block!important;margin:5px 10px!important}
.persummary{height:350px;width:100%;overflow-y:scroll}
.persummaryprint{height:490px;width:94%;margin:5px 0 0 0}
.testsummary{height:750px;width:100%;overflow-y:scroll}
.persummary .MuiDataGrid-root .MuiDataGrid-cell--textLeft
,.testsummary .MuiDataGrid-root .MuiDataGrid-cell--textLeft{ max-width: 80px!important;   width: 150px!important;}
.persummaryprint .MuiDataGrid-root{border: 2px solid #ec008c!important; padding: 0px 0!important;}
.persummaryprint .MuiDataGrid-root .MuiDataGrid-columnsContainer{border-bottom: 2px solid #ec008c!important}
.persummaryprint .MuiDataGrid-root .MuiDataGrid-cell{border-bottom: 2px solid #ec008c!important}
.persummaryprint .MuiDataGrid-root .MuiDataGrid-cell--textLeft{ max-width: 135px!important;   width: 135px!important;font-size:20px;border-right: 2px solid #ec008c;}
.persummaryprint .MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader{
    min-width:150px!important;
}
.page-break {
display: block;
page-break-before: always;
}
.persummaryprint .MuiDataGrid-root .MuiDataGrid-columnHeader{min-width: 135px!important;max-width:  135px!important;   width:  135px!important;font-size:18px;border-right: 2px solid #ec008c;padding:10px 0}
.MuiTableCell-root{font-size:16px!important;padding :10px !important}
.headertopbg{background-color:#b391e5;padding:10px 0}
.main{width:96%;margin:auto}
.exporttab{display:none}
.mobmain{width:98%;margin:auto}
.footerfix ul{margin:0px;padding:0px;text-align:center}
.footerfix li{display:inline-block;margin:2px 2%}
.footerfix li p{margin:0px;padding:0px}
.moblogo {    width: 20%;       /* float: left; */
    display: table-cell;
    vertical-align: middle;}
.moblogo img{width:100%}
.userprofile{width:80%;/*float: right; */
    display: table-cell;
    vertical-align: middle;
}
@media screen {
	/*.yourClassName {
		visibility: hidden;
	}*/
}

@media print {
  .page-break {
    page-break-after: after;
  }
}


.colorprint{color:#ec008c}
.headingbg{background-color:#e5eff1;padding:5px 0}
.headingalign{text-align:center;color:#494d50}.headingalign p{text-align:center;font-size:14px;/*color:#b4bcbf*/}
.headingalign h2{margin:0px 0 5px 0}
.logo img,.innerlogo img{width:100%}
.logo{width:25%;margin:20px 0% 0 8%}
.logodiv{width:30%;text-align:center;margin:auto}
.innerlogo{width:60%;margin:20px auto 0 auto}
.logindiv h1,.logindiv1 h1{font-size:50px;text-align:center}
.logindiv1{margin-top:20px}
.loginvector img{width:100%}
.leftbandlog-col{
	/*background-color:#c0a2ec;*/
	width:55%;float:left;vertical-align:top}
	
	.alertleft{float:left;width:30%}.alertright{float:left;width:70%}
.subjectsdiv{float:left;width:45%}	
	.leftbandlog-col img{width:100%}

.contentseclog-col{width:45%;float:left;vertical-align:middle;margin-top:15%}
.mobscreenrow{background-image:url(../images/mobile-login-bg.jpg);background-repeat:no-repeat;background-size:100% 100%;background-position:top center}
.mobloginfrm{width:80%;margin:0px auto 0 auto;padding-top:20px;color:#fff}
.mobloginfrm .MuiInputLabel-formControl{font-size:22px!important;color:#fff!important}
.mobloginfrm .MuiCheckbox-root svg,.mobloginfrm .MuiTextField-root svg, .mobloginfrm input{color:#fff!important}
.mobloginfrm .MuiTextField-root {border-bottom: 1px solid #fff!important;}

.mobloginfrm .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{color:#fff!important}
.mainscreen-row{background-image:url(../images/bg.jpg);background-repeat:no-repeat;background-size:100% 100%;background-position:top center}
.innerrow{display:table-row;width:100%}
.logindiv{width:60%;margin:auto}
.btnmobSubmit{background-color:#ae7cf7!important;display:inline-block;color:#fff!important}

.btnSubmit{background-color:#000!important;display:inline-block;color:#fff!important}
.formtab,.adminpanel{display:table;width:100%}
.innerleft-col{background-color:#c0a2ec;width:15%;display:table-cell;vertical-align:top}
.contentbg{background-color:#e8e8e8;padding:15px 0;margin-bottom:70px}
.contentsecinner-col{width:75%;display:table-cell;vertical-align:top;background-color:#e8e8e8;border-left:20px solid transparent;border-right:20px solid transparent}
.toploginoptiondiv{border-bottom:1px solid #cdced2;    margin-top: 20px;}
.iconcolor{color:#8f58db!important}
.toploginoptiondiv ul{margin:0px;padding:0px;text-align:right}
.toploginoptiondiv li{display:inline-block;vertical-align:middle;margin:0px 2%}
.toploginoptiondiv li p{padding:0px;font-size:14px;color:#9c9e9f}
.iconvertical{vertical-align:middle}
.adminmenu{margin:50px 0px 0 20px;}
.adminmenu ul{margin:0px;padding:0px;}.adminmenu li{list-style:none}

.adminmenu li{}
.adminmenu li a{cursor:pointer;display:inline-block; position: relative; 
  border-radius: 10px 0 0 10px;vertical-align:middle;margin:8px 0;padding:10px 1% 10px 4%;width:95%;color:#fff}
.adminmenu li a{color:#fff}
.adminmenu li ul li{padding:0%;margin:5px 1%}
.adminmenu li a.active{background-color:#e8e8e8;border-top-left-radius: 10px;border-bottom-left-radius: 10px;color:#000}
.adminmenu li a.active ,.adminmenu li:hover a{color:#000}
.adminmenu li .iconvertical{margin-right:5px;font-size: 30px;}
.active a{color:#000!important}
.statssec-cnt1{float:left;background-color:#8e55e0;width:20%;border-radius:12px;padding:17px 0;
margin:20px 2% 10px 2%
}
.statssec-register p{    margin: 5px 5% 0 5%;}
.attandace-box{float:left;width:44%;border-radius:12px;background-color:#fff;margin:20px 2% 10px 2%}
.statssec-register{float:left;width:20%;border-radius:12px;background-color:#fff;margin:20px 2% 10px 2%}
.admitpad{padding:10px 4% 10px 4%}
.statssec-alerts{float:left;width:40%;border-radius:12px;background-color:#fff;margin:20px 2% 10px 2%}
.panelbox h2{margin-bottom:0px;font-size:17px}
.statssec-register .admitpad{padding:4px 4% 4px 4%}
.panelbox h2 img{width:35px}
.boxheading,.panelbox{border-bottom:1px solid #bcc1c7}
.boxheading h2{margin-bottom:0px;font-size:22px}
.boxheading h2 img,.panelbox h2 img{vertical-align:middle}
.statsicon{float:left;width:30%;text-align:center}
.statsicon svg{font-size:48px;color:#fff}
.statstext{float:left;width:70%;color:#fff}
.statstext p{padding-bottom:5px;font-size:14px;text-align:center}
.statstext h2{margin-bottom:5px;font-size:30px;text-align:center}
.adminmenu li a:hover{
  background: #e8e8e8; color:#000
}
.alerts h4{margin-bottom:5px;}
.adminmenu>ul>li>a.active:after,
.adminmenu>ul>li>a.active:before,.adminmenu>ul>li>a:hover:after,
.adminmenu>ul>li>a:hover:before{
  content:'';
  position: absolute;
  width: 10px; 
  height: 12px;
  right: 0px;
}

.adminmenu>ul>li>a.active:after,.adminmenu>ul>li>a:hover:after{
  top: -10px;
  background: radial-gradient(circle at top left, #c0a2ec 10px, #e8e8e8 11px);
}

.adminmenu>ul>li>a.active:before,.adminmenu>ul>liva:hover:before{
  bottom: -10px;
  background: radial-gradient(circle at bottom left, #c0a2ec 10px, #e8e8e8 11px);
}
.studecontactdet li p {
    text-align: center;
}
.tabfont{font-size:12px;color:#007aff!important}
.headingfont{font-size:17px;color:#007aff!important}
.studentlist{margin:0px;padding:0px}
.studentlist li{vertical-align:top;display:inline-block;width:20%;margin:8px 1%;border:1px solid #dbdfe2;border-radius:10px;text-align:center}
.profilepad{padding:10px 2%}.studprofilimg img{width:100%;border-radius:50%;}
.studprofilimg{width:40%;margin:auto;height:110px}
.admisionbtn{cursor:pointer;background-color:#00c760!important;display:inline-block;padding:5px 15px;color:#fff;border:0px;border-radius:3px}
.admsnpaybtn{cursor:pointer;background-color:#fc595a!important;display:inline-block;padding:5px 15px;color:#fff;border:0px;border-radius:3px}
.admsndisablebtn{cursor:pointer;background-color:#68727b!important;display:inline-block;padding:5px 15px;color:#fff;border:0px;border-radius:3px}
.studentlist .admsndisablebtn{display:none}
.studentlist p{text-align:center}
.pagebox-col{background-color:#fff;border-radius:10px;margin:5px 0 20px 0}
.registerstud{padding:32px 0px}
.registerstud svg{font-size:88px;color:#aaabaf}
.parenthead{margin:20px 0}
.formview ul,.formbtns ul{margin:0px;padding:0px;text-align:right}
.formviewatt{margin:0px auto;width:350px}
.subjects{margin:0px;padding:0px;text-align:left!important}
.formviewatt li{display:block;text-align:center}
.formview li{float:left;width:31%;margin:5px 1%;list-style:none;text-align:left}
.contntpad{padding:12px 2%}
.validation{color:red}
.formbtns li{display:inline-block;    text-align: right;
    float: none;width:unset}
.frmPrimarybtn{cursor:pointer;background-color:#8e55e0!important;display:inline-block;padding:5px 15px!important;color:#fff!important;border:0px;border-radius:7px}
.frmsecondarybtn{cursor:pointer;background-color:#007aff!important;display:inline-block;padding:5px 15px!important;color:#fff!important;border:0px;border-radius:7px}
.exportbtn{cursor:pointer;background-color:#007aff!important;display:inline-block;padding:9px 15px!important;color:#fff!important;border:0px;border-radius:7px;box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);}


.frmPrimarybtn svg{vertical-align:middle}
.MuiInputBase-input{padding:7px 8px!important}
.subjects li{display:inline-block!important;float:none!important;width:28%!important}
.feeforms li{width:48%}
.tabfee span{text-decoration:underline}
.tabfee h2{font-size:35px}
	.feeforms{float:left;width:60%}.feedetailspnl{float:right;width:40%}
	.upload-submitimg {text-align:left;}
.upload-submitimg input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.upload-submitimg {
    position: relative;
    overflow: hidden;
    display: block;
	text-align:left;
}
.studentleftdiv{float:left;width:40%}
.studentrightdiv{float:right;width:59%}
.studentpanel{background-color:#ffffff;border-radius:12px;
margin:20px 0% 20px 0%
}
.studecontactdet{margin:0px;padding:0px}

.studbasicimg{width:30%;margin:0 auto 10px auto}
.studbasicimg img{width:100%;border-radius:50%}
.studecontactdet{margin:0px;padding:0px;text-align:center}
.studecontactdet li{display:inline-block;margin:5px 2%}
.balanceamntbg{background-color:#fc595a;padding:30px 2% 20px 2%;
margin:20px 0% 10px 0%;
border-radius:5px;color:#fff;text-align:center}
.balanceamntbg p{text-align:center;color:#fff;padding-bottom:4px}
.balanceamntbg h2,.balanceamntbg svg{font-size:50px;vertical-align:middle}
.remainde-col h5{background-color:#d7dbdc;padding:2px 5px;display:inline-block;margin-bottom:4px}
.btndonwloads{background-color:#d9dadc!important;display:inline-block;padding:5px 8px!important;border-radius:6px!important}
.btndonwloads:hover {background-color:#01c75e!important;color:#fff}
.btndonwloads:hover svg,.btndonwloads:hover a{color:#fff}
.dropalign{float: right;
    margin-top: 15px;width:20%;padding:5px 10px}
	.rbc-row-segment .rbc-event-content{text-align:center;border-bottom:1px solid #fff}
.searchmaster li	.MuiFormControl-root{margin:0px}
.rbc-date-cell {
    
    padding-right: 0px;
    text-align: center;
   line-height:50px
}.rbc-date-cell h5{margin: auto;width:99%}
.rbc-button-link{font-size: 16px;}
.fileuploaddrop svg{color:#007aff}.fileuploaddrop{width:350px;}
.fileuploaddrop .MuiDropzoneArea-root,.dwnloadright .MuiDropzoneArea-root{border:1px solid #007aff;min-height:150px}
.fileuploaddrop .MuiDropzoneArea-text {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
    text-align: center;
}
.studeattend .rbc-row-segment{display:none}
.studeattend .rbc-header{padding:7px 3px}
.colorindication,.grphcolorindication{margin:10px 0 0 0;padding:0px;text-align:right}
.colorindication li,.grphcolorindication li{display:inline-block;margin:5px 2%}
.colorindication li:first-child span{background-color:#fff;border-radius:50%;width:16px;height:15px;display:inline-block;border:1px solid #ccc;vertical-align: middle;}
.colorindication li:nth-child(2) span{background-color:#fc595a;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}
.colorindication li:nth-child(3) span{background-color:#007aff;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}
.grphcolorindication li:first-child span{background-color:#c0a2ec;border-radius:50%;width:16px;height:15px;display:inline-block;border:1px solid #ccc;vertical-align: middle;}
.grphcolorindication li:nth-child(2) span{background-color:#00dc85;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}
.grphcolorindication li:nth-child(3) span{background-color:#00c9d9;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}
.grphcolorindication li:nth-child(4) span{background-color:#2592fb;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}
.grphcolorindication li:nth-child(5) span{background-color:#fc595a;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}

.grphcolorindication li:nth-child(6) span{background-color:#8b8b8b;border-radius:50%;width:16px;height:15px;display:inline-block;vertical-align: middle;}

.mobnotificationlist ul{margin:20px 0 0 0;padding:0px}
.mobnotificationlist li{list-style:none;margin:10px 1% 15px 1%;border-bottom:1px solid #d8dcdd}
.contentbg h5{font-weight:bold}

.contentbg h5{font-size:18px}
.contentbg h6{font-size:16px}
.mobnotificationlist h6{background-color:#d8dcdd;display:inline-block;padding:3px 4px}
.notheading{float:left;width:60%}
.donwloadnot{float:right;width:40%;text-align:right}
.profileselection p{text-align:right;color:#fff;font-size:19px;cursor:pointer}
.profileselection{width:50%;float:right}
.profileselection img{width:40px;height:40px;object-fit:cover;border-radius:50%;vertical-align:middle;margin-right:7px}
.profiledrop ul{margin:0px;padding:0px;height:unset!important}
.profiledrop {background-color:#fff;width:260px;float:right;position:absolute;right:5px;z-index:1000;border-radius:10px}
.profiledrop ul>li{list-style:none;margin:15px 12px;border-bottom:1px solid #f1f1f1;text-align:left;font-weight:bold}
.profiledrop ul>li>ul li{    display: inline-block;
    margin: 5px 5px 5px 3px;
    font-weight: lighter;
    color: #6711e5;
    border-right: 1px solid #6711e5;
    padding-right: 6px;}
	.profiledrop ul>li>ul li:last-child{border:0px}
.profiledrop li img{width:30px;height:30px;object-fit:cover;border-radius:50%;vertical-align:middle;margin-right:7px}
.expandable{color:#6711e5}
/* jagadamba */
.actionbtns li,.searchmaster li{
	display:inline-block;
	list-style:none;
}
.actionbtns li{
	display:inline-block;
	margin:0px 10px;
}
.searchmaster li{
	display:inline-block;
	width: 31%;
    vertical-align: top;
    margin: 0px 1%;
}
.dwnloadleft{
	float:left;
	width:60%;
	margin-right:2%;
}
.dwnloadright{
	float:left;
	width:38%;
}

.stadrp{
	width:100%;
}
.savebtn button{
background-color:#8e55e0;
    color: #fff;
    border-radius: 5px;	
	padding: 5px 10px;
}
.cancelbtn button{
background-color: #aab2b7;
    color: #fff;
    border-radius: 5px;	
	padding: 5px 10px;
}
.cancelbtn button:hover{
	background-color: #aab2b7;
    color: #fff;
}
.savebtn button:hover{
background-color: #643ba7;
    color: #fff;
}
.css-1ps6pg7-MuiPaper-root{
	border-radius:20px;
}

.searchmaster ul{
padding:0px;margin :10px 0 15px 0
}

.pageheader{
	padding:15px 10px 0px;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root{
	margin-top:0px!important;
}
.formleft{
	float:left;
	width:25%;
}
.tableright{
	float: right;
    width: 75%;
}
.searchbutton{
	margin-top:15px;
	display:inline-block;
	margin-right:10px;
}
.searchbutton button,.searchbutton button:hover/*,.editbtn button*/{
	background-color: #007aff;
    color: #fff;
	border-radius:5px;
	padding: 5px 30px;
}
.searchbutton .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
    
    text-decoration: none;
    background-color: #1565c0;
    border: 0px solid #1565c0;
}
/*.delbtn button{
	background-color: #aa8bd9;
    color: #fff;
	border-radius:5px;
	padding: 5px 30px;
}
.delbtn button:hover{
	background-color: #7a4bc1;
    color: #fff;
}*/
.validation{
	color:red;
}
.cancelbtn{
	float:left;
}
.savebtn{
	float:right;
}
.MuiInputBase-root {
	padding:0px 0px 0px 5px;
}
.actionbtns{
	margin-bottom:20px !important;
}
.addnewbtn{
	
    color: #fff;
    text-align: center;   
	margin-top:15px;
	display:inline-block;
}

.addnewbtn .MuiButtonBase-root{
	width:100%;
	padding: 5px 30px;
    border-radius: 7px;
    background-color: #8e55e0 !important;
}
.addnewbtn .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover{
	border:0px;
}
.containermain{
	background-color:#fff;
	border-radius:20px;
	margin:20px 0px;
	padding-bottom:10px;
}
.css-1ps6pg7-MuiPaper-root{
	width:98%;
	margin:auto;
}
.formlist ul{
	padding:0px;
	
}
.formlist li{
	list-style:none;
	display:inline-block;
	width:32%;
	margin:0px 0.5%;
	text-align:center;
}
.changepassword{
width:98%;
margin:auto;
}
.pageheader img{
	max-width:100%;
	vertical-align:middle;
	margin-right:3px;
}

.frmPrimarybtn{cursor:pointer !important;background-color:#8e55e0!important;display:inline-block !important;padding:5px 15px !important;color:#fff!important;border:0px !important;border-radius:7px !important;margin-right:4px !important;}
.frmsecondarybtn{cursor:pointer !important;background-color:#007aff!important;display:inline-block;padding:5px 15px !important;color:#fff!important;border:0px !important;border-radius:7px !important;}

.admsndisablebtn{cursor:pointer !important;background-color:#68727b!important;display:inline-block;padding:5px 15px !important;color:#fff !important;border:0px !important; border-radius:7px !important;}
.searchnewbtn{
float: right;
    margin-right: 3%;
     
}

.filesupld svg{color:#007aff!important;    font-size: 60px;
    font-weight: lighter;}
	.mediadiv .JoyRadioGroup-colorNeutral{-webkit-flex-direction:unset!important;-ms-flex-direction:unset!important;
	flex-direction:unset!important;}
.filesupld .MuiTypography-h5{text-align:center}

.dwnloadspanel{
	width:70%;
	margin:auto;
}
.formlists ul{margin:0p;padding:0px}
.formlists li{
	list-style:none;
}
.optionstypedrp{
	background-color:#f3f4f6;
	padding:10px;
}
@media(max-width:600px)
{.persummary .MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer {
    width: 100%;
    display: block;
}
.admitpad{padding: 10px 1.5% 10px 1.5%;}
	.persummary .MuiDataGrid-root .MuiDataGrid-columnHeader {
    display: block;
    position: relative;
    align-items: center;
}
	.moblogo {    width: 25%;   }
	
	.persummary .MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader {
    min-width: 100px!important;
}

.persummary .MuiDataGrid-root .MuiDataGrid-cell--textLeft{ max-width: 150px!important;   width: 100px!important;}
}


/*anjali*/


span.profilehover {
   width:25%;
    position: absolute;
    margin-left: -60px;
	margin-top:-15px;
display:none;
	
}
/*.profilename{position:relative;} */


.container {
  display: inline-block;
}
.profileimg {
  display: none;
}
.hover-text:hover ~ .profileimg {
  display: block;  
}
.profileimg img {
    width: 120px;
    position: absolute;
    margin-top: -59px;
    margin-left: 103px;
    border-radius: 50%;
    border: 4px solid #8e55e0;
    height: 120px;
    object-fit: contain;background-color: #fff;
}
.printimg{float:left;width:20%;margin-top:10px}
.printcontnt{float:right;width:79%;margin-top:10px;font-size:18px}
.printimg img{width:100%;height:170px;object-fit:contain;}
/*anjali */
.printcontnt p{text-align:left}
.leftbox{width:20%;float:left;vertical-align:bottom;margin:2px 10px}
.rightbox{width:75%;float:left;border-bottom:1px solid #d90280;padding:10px 5px;margin:2px 0px;}
.studentdiv{    width: 94%;
    margin: 10px auto 5px auto;
    font-size: 20px;}
.classbox{border-bottom:1px solid #d90280;padding:10px 3%}


.printstudentbtn {
    text-align: center;
}

.leftboxclass{width:30%;float:left;    font-size: 18px;}
.leftboxbatch{width:30%;float:right;    font-size: 18px;}
.leftboxbatch p{text-align:right;}
.aligncenter h1{color:#2d3090!important;font-weight:bold;border-bottom:1px solid #2d3090!important}
.aligncenter{width:45%;margin:10px auto 20px auto;}
.pinkbg{background-color:#fae4ee!important;width:95%;margin:20px auto 20px auto;}
.classbox{background-color:white;}
.rightbox{background-color:white;}
.markshistory th{border:1px solid #d90280;width:10%;}
.headerbgimage img{background-repeat:no-repeat;background-size:100% 100%;width:100%;}
.footerbgimage {margin:10px 0px 0px 0px;}
.footerbgimage img{background-repeat:no-repeat;background-size:100% 100%;width:100%;margin:0px 0px 0px 0px;}

.parentdiv ul{margin:0px 0px;text-align:center;}
.parentdiv li{display:inline-block;width:50%;}
.parentdiv li:last-child p{
	text-align:center;
	
}

.leftbox p{vertical-align:bottom!important;padding:10px 10px 3px 10px;}
.hidden-date {
  display:none!important;
}

.has-event {
  background-color:#2588f5!important;
  color: #fff !important;
}
.rbc-off-range-bg {
    background: #ffffff;
    color:white!important;
}
.rbc-off-range {
    color: #ffffff;
}
.rbc-off-range h5{background-color:#fff!important;color:#fff!important}

@media print {
	body {
		font-size: 19px!important;
	}
}